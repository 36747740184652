import $ from "../../../node_modules/cash-dom/dist/cash.esm.js";

$(".mobile-menu-button").on("click", function(e) {
  $("nav")[0].classList.add("active");
});

$(".mobile-menu-close").on("click", function(e) {
  $("nav")[0].classList.remove("active");
});

document.addEventListener('mouseover', function (event) {
  let closest = event.target.closest(".page-header nav:not(.active) > ul > .isParent");
  if (!closest) return;
  closest.classList.add("open");
}, false);

document.addEventListener('mouseout', function (event) {
  let closest = event.target.closest(".page-header nav:not(.active) > ul > .isParent");
  if (!closest) return;
  closest.classList.remove("open");
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".page-header a + button, .page-header .nav-empty-link + button");
  if (!closest) return;

  let $toggle = closest;
  let $navItem = $toggle.closest(`li`);
  let $otherNavItems = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  $otherNavItems.forEach(item => {
    let button = $("button", item);
    item.classList.remove("open");
    if (button) {
      button.attr(`aria-expanded`, `false`);
    }
  })

  $navItem.classList.toggle(`open`);
  if ($toggle.getAttribute(`aria-expanded`) === `true`) {
    $toggle.setAttribute(`aria-expanded`, `false`);
  } else {
    $toggle.setAttribute(`aria-expanded`, `true`);
  }
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".page-header nav.active .isParent");
  if (!closest) return;

  const $navItem = closest;
  const $parent = $navItem.parentNode;
  let siblings = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  event.preventDefault();

  if (closest.classList.contains(`open`)) {
    closest.classList.remove(`open`);
  } else {
    closest.classList.add(`open`)
    siblings.forEach(sibling => {
      sibling.classList.remove("open");
    })
  }
}, false);