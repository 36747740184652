import $ from "../../../node_modules/cash-dom/dist/cash.esm.js";

var headerHeight = $("header")[0].offsetHeight;
var offset = headerHeight - 30;

var scrollz = function() {
  var elementOffset = this.pageYOffset;

  if (elementOffset >= offset && $("header")[0].className.indexOf("sticky") === -1) {
    $("header")[0].classList.add("sticky");
  } else if (elementOffset < offset && $("header")[0].className.indexOf("sticky") != -1) {
    $("header")[0].classList.remove("sticky");
  }
};

window.onscroll = scrollz;
