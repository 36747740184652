// Vendor Libraries
import $ from "../../node_modules/cash-dom/dist/cash.esm.js";

// Other imports (collections, entities, ...)
import "./imports.js";

// Application's Libraries.
//import "./components/modals.js";
import "./components/videos.js";
import "./components/masthead.js";
import "./components/menu.js";
import "./components/header.js";
import "./components/forms.js";

let el = document.createElement("div");
let supportsGrid = "string" == typeof el.style.grid;
let $window = $(window);
let $header = $("header[role='banner']");
let $body = $("body");

if (!supportsGrid) {
  let head = document.getElementsByTagName("head")[0];
  let link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = "/css/blueprint-IE.min.css";
  link.media = "all", head.appendChild(link);
}

let scrollTo = function(id) {
  var $el = $(id);
  if (!$el.length) {
    return;
  }

  let headerOffset = 0 + $header.outerHeight();
  if ($("body").hasClass("samadmin")) {
    headerOffset = headerOffset + 62;
  }

  window.scroll({
    top: $el.offset().top
          - headerOffset
          - 45 /* Extra padding for visual look */,
    behavior: 'smooth'
  });
};

$body.on("click", "a[href^=\"#\"]:not([href=\"#\"]):not([href=\"#Share\"]):not([href=\"#Apply\"]):not(.skip-link):not(.carousel__next):not(.carousel__prev):not([role='tab'])", function(event) {
  event.preventDefault();
  let selector = $(this).attr("href").substring(1);
  scrollTo("#" + selector);
});

$window.on("load", function() {
  // Had to wait for page to load so the header has dimension applied.
  var fragment = window.location.hash;
  if (!fragment) {
    return;
  }

  scrollTo(fragment);
});
