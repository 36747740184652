import $ from "../../../node_modules/cash-dom/dist/cash.esm.js";

const inputs = document.querySelectorAll(".page-container form input, .page-container form select, .page-container form textarea");

inputs.forEach(input => {
  input.addEventListener(
    "invalid",
    event => {
      input.classList.add("error");
    },
    false
  );

  input.addEventListener("blur", function() {
    input.checkValidity();
  });
});


