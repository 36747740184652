import $ from "../../../node_modules/cash-dom/dist/cash.esm.js";

var runAnimation = function(animation) {
  var enter = $(".enter", animation)[0];
  var exit = $(".exit", animation)[0];

  enter.classList.remove("enter");
  enter.classList.add("exit");
  exit.classList.remove("exit");

  if (enter.nextElementSibling === null) {
    $(".frame1", animation)[0].classList.add("enter");
  } else {
    enter.nextElementSibling.classList.add("enter");
  }
};

$(".animation").each(function(animation) {
  window.setInterval(runAnimation, 2000, animation);
});